// Logo's block
.logos-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 $default-margin;

    + .swiper-navigation {
        margin-top: -($default-margin * .5);
    }

    .slider-item {
        width: calc(16.67% - 16px);
        margin: 0 20px 0 0;
        padding: 10px 20px;

        @include media-breakpoint-down(lg) {
            width: calc(33.33% - 8px);
        }

        @include media-breakpoint-down(sm) {
            width: calc(50% - 4px);
        }

        a {
            position: relative;
            display: block;

            &::after {
                position: absolute;
                left: 50%;
                right: 50%;
                bottom: 0;
                height: 2px;
                content: "";
                opacity: 0;
                z-index: 1;
                background-color: $primary;
                transition: all .3s ease-in-out;
            }

            &:hover,
            &:focus {
                &::after {
                    left: 0;
                    right: 0;
                    opacity: 1;
                }
            }
        }
    }
}
