.footer {
    position: relative;
    flex-shrink: 0;
    background-color: var(--bs-body-bg);

    &-top {
        @extend .container-md;
        padding-top: 2rem;

        h2 {
            margin-bottom: 1rem;
        }
    }

    &-bottom {
        display: flex;
        flex-wrap: wrap;
        font-size: rem-convert(14px);
        line-height: rem-convert(24px);
        padding: 20px 15px 0;
        background-color: var(--bs-tertiary-bg);

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: center;
        }
    }

    .copyright {
        display: inline-block;
        margin: 0 0 20px;
    }

    .social-share {
        margin: -4px 0 10px auto;

        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}
