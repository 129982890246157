.social-share {
    $self: &;
    position: relative;

    &-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 300px;
        max-height: 0;
        padding: 0 10px;
        background-color: $color-white;
        overflow: hidden;
        z-index: 9998;
        transition: all .6s;

        @include media-breakpoint-down(sm) {
            left: 0;
            right: 0;
            width: auto;
        }
    }

    &.active {
        #{ $self }-wrapper {
            max-height: 290px;

            @include media-breakpoint-down(sm) {
                max-height: 480px;
            }
        }

        .list {
            &-social-share {
                li {
                    opacity: 1;
                }

                li:nth-child(1) {
                    transition-delay: .15s;
                }

                li:nth-child(2) {
                    transition-delay: .2s;
                }

                li:nth-child(3) {
                    transition-delay: .25s;
                }

                li:nth-child(4) {
                    transition-delay: .3s;
                }

                li:nth-child(5) {
                    transition-delay: .35s;
                }

                li:nth-child(6) {
                    transition-delay: .4s;
                }

                li:nth-child(7) {
                    transition-delay: .45s;
                }
            }
        }
    }

    h2 {
        position: relative;
        display: block;
        padding: 10px 10px 10px 50px;
        margin: 0 -10px 10px;
        color: $text-color;
        font-size: rem-convert(16px);
        line-height: rem-convert(30px);
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        background-color: $gray-100;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        &::before {
            @include icon(share, $text-color, 20px, 20px);
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            width: 30px;
            height: 30px;
            content: "";
        }
    }

    .list {
        &-social-share {
            li {
                opacity: 0;
                transition: all .25s;
            }
        }
    }

    input {
        width: 100%;
        height: 40px;
        color: $text-color;
        font-family: $text-font;
        font-size: rem-convert(14px);
        line-height: rem-convert(22px);
        padding: 9px 20px;
        margin: 0 0 10px;
        background-color: $gray-100;
        border: 0;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .btn-close {
        --bs-btn-close-focus-shadow: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        padding: 0;
    }
}
