.pagination {
    --bs-pagination-border-radius: 50%;
    --bs-pagination-color: #{$color-white};
    --bs-pagination-hover-color: #{$color-white};

    margin: 0 0 2rem;
    justify-content: center;

    .page-item {
        margin: 0 5px;

        &.active {
            .page-link {
                font-weight: $font-weight-medium;
                background-color: darken($primary, 10%);
            }
        }
    }

    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem-convert(44px);
        height: rem-convert(44px);
        border-radius: 50%;
        background-color: $primary;
        border: 0;

        &:hover {
            background-color: darken($primary, 10%);
        }

        &:focus-visible {
            background-color: darken($primary, 10%);
            outline: 2px solid $primary;
        }
    }
}
