// Specifications block
.specification {
    display: flex;
    flex-direction: column;

    .is-h2,
    .is-h3 {
        margin: 0 0 10px;
    }

    .list {
        margin: 0 0 10px;
    }
}
