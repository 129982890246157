// Products block
.products-slider {
    $self: &;
    display: flex;
    flex-direction: row;
    margin: 0 0 $default-margin;

    + .swiper-navigation {
        margin-top: -($default-margin * .5);
    }

    .slider-item {
        @include make-col(3.5);
        padding: 0 15px;

        @include media-breakpoint-down(lg) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(9.5);
        }

        .product {
            $self: &;
            display: flex;
            flex-direction: column;
            margin: 0;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 0 0 20px rgba($color-black, .1);

            &__image {
                margin: 0 0 $default-margin;

                + #{ $self }__content {
                    margin-top: -$default-margin;
                }
            }

            &__content {
                padding: 20px 20px 0;

                .is-h3 {
                    + .richtext {
                        margin-top: -$default-margin;
                    }
                }
            }
        }
    }
}

.swiper {
    &-products-slider {
        overflow: initial;
        margin: 0 -10px;

        .swiper-slide {
            height: auto;
        }
    }
}
