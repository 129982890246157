.navigation {
    @include media-breakpoint-down(lg) {
        margin: 0;
    }

    ul {
        @include media-breakpoint-down(lg) {
            display: block;
            padding: 0 15px;
        }

        li {
            a {
                color: $text-color;
                font-size: rem-convert(16px);
                line-height: rem-convert(24px);
                font-weight: $font-weight-medium;
                padding: 6px 5px;
            }

            &.active {
                > a {
                    color: $primary;
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrow, $text-color, 15px, 14px);
                    position: absolute;
                    top: 8px;
                    right: 0;
                    display: none;
                    width: 26px;
                    height: 26px;
                    padding: 0;
                    transform: rotate(0deg);
                    transition: all .3s;

                    @include media-breakpoint-down(lg) {
                        display: block;
                    }
                }

                &:hover,
                &:focus {
                    > .toggle-subnav {
                        @include icon(arrow, $primary, 15px, 14px);
                    }
                }

                &.active {
                    > .toggle-subnav {
                        @include icon(arrow, $primary, 15px, 14px);
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        transform: rotate(180deg);
                    }

                    > .subnav {
                        @include media-breakpoint-down(lg) {
                            visibility: visible;
                            opacity: 1;
                            display: block;
                        }
                    }
                }

                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $primary, 14px, 14px);
                        transform: rotate(0deg);
                    }
                }
            }

            .subnav {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: -99999px;
                display: block;
                width: 200px;
                padding: 0 5px;
                background-color: $color-white;

                @include media-breakpoint-down(lg) {
                    position: static;
                    display: none;
                    width: auto;
                    margin: 0;
                    padding: 0 0 0 20px;
                    background-color: transparent;
                }
            }

            &:hover,
            &:focus-within {
                .subnav {
                    visibility: visible;
                    opacity: 1;
                }

                > a {
                    color: $primary;
                }

                > ul {
                    left: auto;
                }
            }
        }
    }

    .offcanvas {
        width: 100%;
        top: $header-height;
    }

    &-footer {
        ul {
            margin: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: $text-color;
                    font-size: rem-convert(14px);
                    line-height: rem-convert(24px);
                    padding: 0;
                }
            }
        }
    }

    &-anchor {
        margin: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -5px;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: $color-black;
                    font-size: rem-convert(16px);
                    line-height: rem-convert(24px);
                    font-weight: $font-weight-medium;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                &:hover,
                &:focus {
                    > a {
                        color: $primary;
                        text-decoration: none;
                    }
                }

                &.active {
                    > a {
                        color: $primary;
                    }
                }
            }
        }
    }

    &-side {
        margin: 0 0 $default-margin;

        h2 {
            font-size: rem-convert(25px);
            line-height: normal;
        }

        ul {
            li {
                border-bottom: 1px solid $gray-100;

                &:last-of-type {
                    border-bottom: 0;
                }

                ul {
                    padding: 0 0 0 20px;
                    border-top: 1px solid $gray-100;
                }
            }
        }
    }
}
