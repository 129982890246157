.faq {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.accordion-faq {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: 0;
    --bs-accordion-btn-padding-x: 0.75rem;
    --bs-accordion-btn-padding-y: 0.5rem;
    --bs-accordion-body-padding-x: 0.75rem;
    --bs-accordion-body-padding-y: 1rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin: 0 0 2rem;

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }

    .accordion-header {
        line-height: 1.5rem;
    }

    .accordion-button {
        align-items: normal;
        gap: .75rem;

        &::after {
            margin-top: 4px;
        }
    }

    .accordion-body {
        p {
            margin-bottom: 10px;
        }
    }
}
