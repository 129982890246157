.breadcrumb {
    display: block;
    padding: 15px 0;
    margin: -30px 0 30px;
    border-radius: 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &-list {
        display: flex;
        padding: 0;
        margin: 0;
        font-size: rem-convert(13px);
    }

    &-item {
        a {
            color: $primary;
        }

        &.active {
            a {
                color: $color-black;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                &::before {
                    color: $color-black;
                }
            }
        }
    }
}
