.block {
    $self: &;
    position: relative;
    margin: 0 0 ($default-block-margin - $default-margin);
}

// Articles

.articles {
    &--list {
        margin: 0 0 20px;
    }
}

.article {
    &--block {
        @include make-row;
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .article {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            @include make-col(4);
            padding: 0 15px;
            margin: 0 0 30px;

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }

            img {
                transition: transform .3s;
            }
        }

        &__content {
            @include make-col(8);
            padding: 0 15px;
            margin: 0 0 10px;

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }
        }
    }

    &--reverse {
        justify-content: flex-end;

        .article {
            &--block {
                &__image {
                    order: 2;

                    @include media-breakpoint-down(lg) {
                        order: 1;
                    }
                }

                &__content {
                    order: 1;

                    @include media-breakpoint-down(lg) {
                        order: 2;
                    }
                }
            }
        }
    }
}

// Gallery

.gallery {
    &--block {
        &__preview {
            position: relative;
            margin: 0 0 20px;

            a {
                display: block;
            }
        }
    }

    &--list {
        @include make-row;
        margin: 0 -10px 10px;

        &__item {
            @include make-col(4);
            padding: 0 10px;
            position: relative;
            margin: 0 0 20px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            a {
                display: block;
            }
        }
    }
}

// Search

.search-results {
    &--list {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;

        &__item,
        &__empty-item {
            display: flex;
            flex-direction: column;
        }

        h2 {
            margin-bottom: 0;
            font-size: rem-convert(42px);
            line-height: rem-convert(44px);
        }

        .url {
            display: block;
            color: $primary;
            margin-bottom: 20px;
            font-size: rem-convert(14px);
            line-height: rem-convert(20px);
            font-style: italic;
        }
    }
}

.search-result {
    &--block {
        color: $text-color;
        text-decoration: none;
        margin: 0 0 10px;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }
}

// Google maps

.maps {
    &--block {
        width: 100%;
        height: 500px;
        margin: -30px 0 30px;

        .map {
            width: 100%;
            height: 100%;
        }
    }
}

// Locations

.locations {
    &--list {
        @include make-row;
        margin: 0 -15px 20px;

        &__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}

.location {
    &--block {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .location {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            margin: 0 0 15px;

            img {
                transition: transform .3s;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 0 0 10px;
        }
    }
}

// Persons

.persons {
    &--list {
        @include make-row;
        margin: 0 -15px 20px;

        &__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}

.person {
    &--block {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .person {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            margin: 0 0 15px;

            img {
                transition: transform .3s;
                border-radius: 50%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 0 0 10px;
        }
    }
}

// Calendar/Events

.events {
    &--list {
        @include make-row;
        margin: 0 -15px 20px;

        &__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}

.event {
    &--block {
        color: $text-color;
        text-decoration: none;
        display: block;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .event {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            position: relative;
            margin: 0;
            overflow: hidden;

            img {
                transition: transform .3s;
            }

            time {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                padding: 5px 15px;
                background-color: $color-black;
                color: $color-white;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 0 0 10px;
            padding: 10px 15px;
            background: $color-white;

            h2 {
                font-size: rem-convert(28px);
                line-height: normal;
            }
        }
    }
}

.naw {
    &--list {
        display: block;
        margin: 15px 0;

        &__item {
            margin: 0 10px 10px 0;
            width: 100%;
            padding: 0;
            font-size: rem-convert(16px);
            line-height: normal;
            font-weight: $font-weight-bold;
            display: flex;
            flex-wrap: wrap;

            span {
                &:first-of-type {
                    width: 20px;
                    font-weight: $font-weight-normal;
                    color: $primary;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.page-bar-top {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -20px 0 40px;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down(xs) {
        margin: 0 0 20px;
    }

    form {
        margin: 0;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .form-control {
            background-color: $color-white;
            font-size: rem-convert(14px);
            line-height: rem-convert(16px);
            font-weight: $font-weight-normal;
            height: 38px;
            min-width: 190px;
            max-width: 100%;

            @include media-breakpoint-down(lg) {
                min-width: unset;
            }

            &:focus {
                background-color: $color-white;
            }
        }

        .btn-search {
            @include icon(search, $primary, 16px, 16px);
            width: 38px;
            height: 38px;
            background-color: transparent;
            border-color: transparent;
            position: absolute;
            right: 3px;
            top: 0;
            text-indent: -99999px;
            border-radius: 50%;
            display: block;
        }

        &__item {
            display: flex;
            align-items: center;
            position: relative;
            margin: 0 20px 0 0;

            @include media-breakpoint-down(lg) {
                margin-bottom: 15px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .form-control {
                &--select {
                    &:not([size], [multiple]) {
                        height: 38px;
                        margin-left: 10px;
                    }
                }
            }
        }

        label {
            font-size: rem-convert(14px);
            line-height: rem-convert(16px);
            font-weight: $font-weight-normal;
            margin: 0 20px 0 0;
        }

        label,
        select {
            color: $primary;
        }
    }
}

.author {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    &__image {
        margin-right: 25px;
        border-radius: 50%;
        overflow: hidden;
        width: 96px;
        height: 96px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto 20px;
        }
    }

    &__title {
        font-weight: $font-weight-normal;
        font-size: rem-convert(14px);
        line-height: rem-convert(26px);
        margin-bottom: 0;
    }

    &__info {
        max-width: 80%;
        display: flex;
        align-content: center;
        flex-flow: column wrap;

        @include media-breakpoint-down(lg) {
            max-width: unset;
            flex: 1;
            align-content: baseline;
        }

        .is-h3 {
            font-size: rem-convert(32px);
            line-height: rem-convert(43px);
            margin: 0;

            @include media-breakpoint-down(sm) {
                font-size: rem-convert(25px);
            }
        }
    }
}
