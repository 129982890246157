.modal {
    .modal-header {
        display: flex;

        h2,
        h3 {
            margin: 0;
        }
    }

    &.lightbox {
        .modal-dialog {
            max-width: fit-content;
        }

        .modal-content {
            overflow: hidden;
            max-width: 1280px;
        }

        .modal-body {
            padding: 0;
        }

        .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            border: none;
        }

        iframe {
            width: 960px;
            height: 640px;
            max-width: 100vw;
            max-height: 100vh;
        }

        .btn-close {
            --bs-btn-close-focus-shadow: none;
            border-radius: 0;
            background-color: transparentize($color: $white, $amount: 0.9) !important;
        }

        .ratio {
            --bs-aspect-ratio: none;

            > * {
                position: relative;
            }
        }
    }
}
