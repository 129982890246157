/* roboto-condensed-300 - latin */
@font-face {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-300.woff2") format("woff2"),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-300italic - latin */
@font-face {
    font-family: "Roboto Condensed";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-300italic.woff2") format("woff2"),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-regular - latin */
@font-face {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-regular.woff2") format("woff2"),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-italic - latin */
@font-face {
    font-family: "Roboto Condensed";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-italic.woff2") format("woff2"),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-700 - latin */
@font-face {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-700.woff2") format("woff2"),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-700italic - latin */
@font-face {
    font-family: "Roboto Condensed";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-700italic.woff2") format("woff2"),
        url("/assets/fonts/roboto-condensed/roboto-condensed-v25-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-900 - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/source-sans-pro/source-sans-pro-v21-latin-900.woff2") format("woff2"),
        url("/assets/fonts/source-sans-pro/source-sans-pro-v21-latin-900.woff") format("woff"), /* Modern Browsers */
}

// Fallback font when font is not being loaded yet
@font-face {
    font-family: "Source Sans Pro-fallback";
    size-adjust: 93.75%;
    ascent-override: 110%;
    src: local("Arial");
}

@font-face {
    font-family: "Roboto Condensed-fallback";
    size-adjust: 88.91%;
    ascent-override: 110%;
    src: local("Arial");
}
