.block {
    &--article {
        $self: &;
        margin: 0 0 60px;
    }
}

.article {
    &__title {
        @include make-col(7);
        display: flex;
        justify-content: center;
        margin: 0 auto 40px;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            margin: 0;
        }

        .is-h3 {
            margin-bottom: 10px;
            display: block;
            text-align: center;
            line-height: 55px;
        }

        .is-h1 {
            margin-bottom: 10px;
            display: block;
            text-align: center;
        }

        .info-publish {
            display: block;
            text-align: center;
            margin-top: -10px;
            font-size: rem-convert(14px);
            line-height: rem-convert(28px);
        }
    }

    &__content {
        @include make-col(8);
        display: flex;
        flex-direction: column;
        margin: 0 auto $default-margin;
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .richtext {
            margin: 0;
        }

        &--small {
            text-align: center;
            padding: 0 10%;

            @include media-breakpoint-down(lg) {
                padding: 0;
            }
        }
    }

    &__image {
        @include make-col(10);
        display: flex;
        flex-direction: column;
        margin: 0 auto 40px;
        padding: 0 5px;
    }

    &__tags {
        @include make-col(8);
        display: flex;
        justify-content: center;
        margin: 0 auto 20px;
        flex-direction: column;
        border-bottom: 1px solid rgba($primary, .2);
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .form {
            align-self: center;
            min-width: 45%;
        }
    }

    &__author {
        @include make-col(8);
        padding: 0 5px;
        margin: 0 auto 20px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}
