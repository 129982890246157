// Hero block
.hero {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    height: 475px;

    @include media-breakpoint-down(sm) {
        height: 300px;
    }

    .image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        picture {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .content {
        @extend .container;

        .richtext {
            width: 40%;
            font-size: rem-convert(28px);
            line-height: rem-convert(38px);
            font-weight: $font-weight-bold;
            margin-left: auto;

            @include media-breakpoint-down(lg) {
                width: 75%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            .is-h2 {
                &:last-child {
                    font-size: rem-convert($text-size);
                    line-height: rem-convert($line-height);
                    margin-top: -20px;
                }
            }
        }
    }
}
