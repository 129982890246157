// Form image block

.block {
    &--form-image {
        display: flex;
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.form-image {
    $self: &;

    @include make-row;
    margin: 0 0 $default-margin;

    &__image {
        @include make-col(6);
        margin: 0 auto 0 0;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &__content {
        @include make-col(6);
        margin: 0 0 0 auto;
        padding: 30px 30px 0;
        background-color: $color-white;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &--reversed {
        #{ $self }__image {
            order: 2;
            margin: 0 0 0 auto;

            @include media-breakpoint-down(sm) {
                order: 1;
            }
        }

        #{ $self }__content {
            order: 1;
            margin: 0 auto 0 0;

            @include media-breakpoint-down(sm) {
                order: 2;
            }
        }
    }
}
