form {
    label {
        display: block;
        cursor: pointer;
    }

    select {
        appearance: none;
        user-select: none;

        &.form-control {
            &:not([size], [multiple]) {
                height: 57px;
            }
        }
    }

    [type="search"] {
        &::-webkit-search-cancel-button {
            @include icon(close, $primary, 18px, 18px);
            appearance: none;
            height: 24px;
            width: 24px;
            margin-left: .4em;
            cursor: pointer;
        }
    }
}

.form {
    .form-message {
        padding: 10px 10px 0;
        color: $color-red;
        background: rgba($color-red, .1);
        border-left: 4px solid $color-red;
        margin: 0 0 10px;

        > * {
            margin-bottom: 10px;
        }

        a {
            color: $color-red;
            text-decoration: underline;
        }

        &--success {
            color: $color-green;
            background: rgba($color-green, .1);
            border-left: 4px solid $color-green;

            a {
                color: $color-green;
            }
        }
    }

    .form-group {
        margin: 0 0 1.5rem;

        > label {
            font-weight: $font-weight-medium;
        }

        &.has-error {
            .form-control {
                border-color: $color-red;
            }

            .help-block {
                color: $color-red;
                margin: 0;
            }
        }
    }

    .form-control-wrapper {
        > * {
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .form-control {
        border-radius: 0;

        &-textarea {
            height: 120px;
        }

        &--select {
            @include icon(select, $color-black, 14px, 14px);
            background-position: center right 10px;
            background-color: $color-white;

            &:focus-visible {
                outline: 1px solid $primary;
            }
        }

        &--check {
            position: relative;
            padding: 0 0 0 28px;
            margin: 0 0 10px;
            border: 0;
            border-radius: 0;
            background-color: transparent;

            label {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }

            input[type="radio"],
            input[type="checkbox"] {
                opacity: 0;
                position: absolute;

                + i {
                    position: absolute;
                    left: 0;
                    top: 7px;
                    display: block;
                    width: 18px;
                    height: 18px;
                    border: 2px solid $color-gray;

                    &::before {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        display: none;
                        width: 12px;
                        height: 12px;
                        content: "";
                        background-color: $primary;
                    }
                }

                &:checked {
                    + i {
                        &::before {
                            display: block;
                        }
                    }
                }

                &:focus-visible {
                    + i {
                        outline: 1px solid $primary;
                    }
                }
            }

            input[type="radio"] {
                + i {
                    border-radius: 50%;

                    &::before {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.form-horizontal {
    .form-group {
        @include make-row;
        margin: 0 0 10px;

        label {
            margin: 0;
        }

        > label {
            @include make-col(3);
            padding: 11px 10px 11px 0;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
                margin: 0 0 10px;
            }

            + .form-control-wrapper {
                padding: 0 0 0 10px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }

    .form-control-wrapper {
        @include make-col(9);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.form-inline {
    flex-flow: row wrap;
    align-items: baseline;
    margin-left: -10px;
    margin-right: -10px;

    .form-message {
        flex: 1 100%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .form-group {
        flex: 1;
        flex-direction: column;
        padding: 0 10px;
        margin: 0 0 10px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 100%;
        }

        &:last-child {
            flex: 0;
        }

        .form-control,
        .help-block {
            width: 100%;
        }

        .btn {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.form-search {
    position: relative;

    .form-control {
        width: 100%;
        padding: 12px 45px 12px 15px;
        border-color: $color-gray;
        font-size: rem-convert(18px);
        line-height: normal;
    }

    .btn {
        @include icon(search, $color-gray, 20px, 20px);
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 40px;
        height: 100%;
        padding: 0;
        font-size: 0;
        border-color: transparent;
        background-color: transparent;
        border-radius: 0;

        &:hover,
        &:focus {
            @include icon(search, $primary, 20px, 20px);
        }
    }
}

.hbspt-form {
    fieldset {
        padding: 0 !important;
        max-width: none !important;
    }

    .hs-main-font-element {
        display: none;
    }

    .hs-form-field {
        @extend .form-group;

        .input {
            margin: 0 !important;
        }
    }

    .form-columns-1 {
        .hs-input {
            width: 100% !important;
        }
    }

    .form-columns-2 {
        margin: 0 -10px;

        .form-group {
            width: 50%;
            padding: 0 10px;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
    }

    .hs-error-msgs {
        .hs-error-msg {
            @extend .help-block;
            color: $color-red !important;
        }
    }

    .no-list,
    .inputs-list {
        margin: 0;
    }

    .hs-input {
        @extend .form-control;

        &.error {
            border-color: $color-red;
        }
    }

    .hs-button {
        @extend .btn;
    }

    .hs-form-booleancheckbox-display {
        &:last-of-type {
            margin: 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + span {
                position: relative;
                padding: 0 0 0 25px;
                margin: 0 0 10px;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: "";
                    border: 2px solid $primary;
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    display: none;
                    width: 10px;
                    height: 10px;
                    content: "";
                    background-color: $primary;
                }
            }

            &:checked {
                + span {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        input[type="radio"] {
            + span {
                &::after {
                    border-radius: 50%;
                }

                &::before {
                    border-radius: 50%;
                }
            }
        }
    }
}

::input-placeholder {
    color: $color-gray;
    font-style: italic;
}

::placeholder {
    color: $color-gray;
    font-style: italic;
}

:input-placeholder {
    color: $color-gray;
    font-style: italic;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}
