*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

:root {
    font-family: $text-font;
    font-size: $text-size;
    line-height: rem-convert($line-height);
    font-weight: $font-weight-normal;
    color: $text-color;

    @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: rem-convert(22px);
    }
}

html,
body {
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
}

body {
    display: flex;
    flex-direction: column;
    background-color: var(--bs-tertiary-bg);
}

[inert] {
    pointer-events: none;
    cursor: default;
    user-select: none;

    * {
        user-select: none;
    }
}

html {
    &.dialog-open {
        overflow: hidden;
    }

    &.primary-navigation-active {
        @include media-breakpoint-down(lg) {
            overflow: hidden;

            .btn {
                &-to-top {
                    display: none !important;
                }
            }
        }
    }
}

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

::selection {
    background-color: $gray-100;
}

.search-highlight {
    background-color: $gray-100;
}

.date {
    font-weight: $font-weight-light;
    font-size: rem-convert(15px);
}

iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

li {
    &:focus-visible {
        outline: 1px solid $primary;
    }
}

a,
.link {
    color: $primary;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $text-color;
        outline: none;
        text-decoration: underline;
    }

    &:focus-visible {
        outline: 1px solid $primary;
    }
}

// Set link color to contrast background color
@each $color, $value in $theme-colors {
    .bg-#{$color} {
        a:not(.btn),
        .link {
            color: color-contrast($value);

            &:hover,
            &:focus {
                color: tint-color(color-contrast($value), 30%);
            }
        }
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.date-label {
    background: $color-black;
    color: $color-white;
    font-size: rem-convert(14px);
    line-height: rem-convert(14px);
    font-weight: $font-weight-bold;
    text-align: left;
    padding: .5em .75em;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

table {
    width: 100%;
    border-collapse: collapse;

    &.table-wrapper {
        margin: 0 0 $default-margin;
        overflow-x: scroll;

        table {
            margin: 0;

            tr {
                th,
                td {
                    @include media-breakpoint-down(lg) {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}

ul,
ol {
    padding: 0;
    list-style-type: none;
}

img {
    height: auto;
    max-width: 100%;
}

.location-image {
    &--hidden {
        display: none;
    }
}

.info {
    display: block;
    font-style: italic;
    margin: 0 0 $default-margin;
    color: $text-color;
}

.video-wrapper {
    text-align: center;
    margin: 0 0 $default-margin;

    &__inner {
        position: relative;
        display: block;
        padding: 1.5em 0 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.is-video {
    position: relative;

    &::after {
        @include icon(play, $color-white, 60px, 60px);
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        content: "";
    }

    &:hover,
    &:focus {
        &::after {
            @include icon(play, $primary, 60px, 60px);
        }
    }
}

.icon {
    display: inline-block;
    height: 40px;
    width: 40px;

    &--check {
        @include icon(check, $primary, 40px, 40px);
    }

    &--phone {
        @include icon(phone, $color-white, 14px, 14px);
    }

    &--instagram {
        @include icon(instagram, $color-white, 18px, 18px);
    }

    &--linkedin {
        @include icon(linkedin, $color-white, 18px, 18px);
    }

    &--email {
        @include icon(email, $color-white, 18px, 18px);
    }
}

picture,
figure {
    display: block;
}

section {
    &.main {
        position: relative;
        flex: 1 0 auto;
        margin: 0;
    }
}

.wrapper {
    @extend .container;
}

.skiptocontent {
    padding: .6em 1em;
    position: absolute;
    transform: translateY(-100%);
    transition: transform .3s ease-in-out;
    left: 50%;
    color: $color-white;
    background: $primary;
    border: 0;
    z-index: 1000;

    &:focus {
        transform: translateY(0%);
        text-decoration: none;
    }
}

.dialog-box {
    display: grid;
    background: $color-white;
    color: $text-color;
    max-inline-size: min(90vw, 60ch);
    max-block-size: min(80vh, 100%);
    max-block-size: min(80dvb, 100%);
    margin: auto;
    padding: 0;
    position: fixed;
    inset: 0;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba($color-black, .2);
    z-index: 9999;
    overflow: hidden;
    transition: opacity .5s cubic-bezier(.25, 0, .3, 1);

    @media (prefers-reduced-motion: no-preference) {
        animation: scale-down .5s cubic-bezier(.25, 0, .3, 1) forwards;
        animation-timing-function: cubic-bezier(.5, -.5, .1, 1.5);
    }

    &:not([open]) {
        pointer-events: none;
        opacity: 0;
    }

    &::backdrop {
        background-color: rgba($color-black, .5);
        backdrop-filter: blur(5px);
        transition: backdrop-filter .5s ease;
    }

    &[loading] {
        visibility: hidden;
    }

    &[open] {
        @media (prefers-reduced-motion: no-preference) {
            animation: slide-in-up .5s cubic-bezier(.25, 0, .3, 1) forwards;
        }
    }

    > form {
        display: grid;
        grid-template-rows: auto 1fr auto;
        align-items: start;
        max-block-size: 80vh;
        max-block-size: 80dvb;

        > article {
            overflow-y: auto;
            max-block-size: 100%; /* safari */
            overscroll-behavior-y: contain;
            display: grid;
            justify-items: flex-start;
            gap: 1rem;
            box-shadow: var(--shadow-2);
            z-index: var(--layer-1);
            padding-inline: 1.5rem;
            padding-block: 1rem;
        }

        > header {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: flex-start;
            padding-block: 1rem;
            padding-inline: 1.5rem;

            > button {
                @include icon(close, $color-black, 40px, 40px);
                border-radius: 50%;
                padding: .75ch;
                aspect-ratio: 1;
                flex-shrink: 0;
                place-items: center;
                stroke-width: 3px;
                background-size: contain;
            }
        }
    }
}
