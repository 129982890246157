// Tagline block

.block {
    &--tagline {
        display: flex;
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.tagline {
    $self: &;
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__content {
        text-align: center;
    }

    &--dark {
        #{ $self }__title {
            > * {
                color: $color-black;
            }
        }

        #{ $self }__content {
            > * {
                color: $color-black;
            }
        }
    }

    &--light {
        #{ $self }__title {
            > * {
                color: $color-gray;
            }
        }

        #{ $self }__content {
            > * {
                color: $color-gray;
            }
        }
    }
}
