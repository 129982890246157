.layout {
    position: relative;
    padding: 2rem 0;
    margin: 0;
    overflow: hidden;

    &:has(.hero-image) {
        min-height: 30vw;
    }
}
