.slider {
    $self: &;
    display: flex;
    margin: 0 0 2rem;

    &-item {
        $self: &;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        .image {
            width: 100%;

            a {
                display: block;
            }
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40%;
            padding: 0 15px;
            transform: translateY(-50%);

            @include media-breakpoint-down(lg) {
                left: 30%;
                width: 60%;
            }

            @include media-breakpoint-down(sm) {
                position: static;
                width: 100%;
                padding: 30px 30px 0;
                background-color: $color-white;
                transform: none;
            }
        }
    }
}

.swiper {
    .swiper-pagination-bullets {
        margin: 0 0 1rem;
    }
}
