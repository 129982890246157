.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style: none;

    li {
        display: block;

        a {
            display: block;
            color: $text-color;
        }
    }

    &-usps {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 15px;
            display: flex;
            margin: 0 0 $default-margin;

            .icon {
                height: 55px;
                width: 55px;
                margin: 0 15px 0 0;

                &-check {
                    @include icon(check, $primary, 55px, 55px);
                }
            }

            .text {
                display: flex;
                align-items: center;
                flex: 1;
                font-size: rem-convert(16px);
                line-height: rem-convert(24px);
                margin: 0;
            }

            @include media-breakpoint-down(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 15px;
            }
        }
    }

    &-sites {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 5px;
            margin: 0 0 10px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a:not(.btn) {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 7px 15px 7px 45px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: $primary;
                border-bottom: 1px solid $color-white;
                font-weight: $font-weight-medium;

                &::after {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    display: block;
                    width: 16px;
                    height: 11px;
                    content: "";
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            &.en {
                a {
                    &::after {
                        background: url("/assets/img/countries/gb.gif") no-repeat center center;
                    }
                }
            }

            &.de {
                a {
                    &::after {
                        background: url("/assets/img/countries/de.gif") no-repeat center center;
                    }
                }
            }

            &.nl {
                a {
                    &::after {
                        background: url("/assets/img/countries/nl.gif") no-repeat center center;
                    }
                }
            }
        }
    }

    // @TODO
    &--tags {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 30px;

        li {
            margin: 0 10px 10px 0;

            &::before {
                display: none;
            }

            a {
                display: inline-block;
                color: $text-color;
                border: 2px solid $primary;
                font-size: rem-convert(16px);
                line-height: 28px;
                padding: 0 14px;
                font-weight: $font-weight-normal;
                background-color: transparent;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: none;
                    background-color: $primary;
                }
            }
        }
    }

    &-social-follow {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 20px;

        li {
            display: inline-block;
            margin: 0 5px 20px;

            a {
                position: relative;
                display: block;
                width: 36px;
                height: 36px;
                font-size: 0;
                border-radius: 50%;
                background-color: $primary;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    content: "";
                }
            }

            &.facebook {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-facebook;
                    }

                    &::after {
                        @include icon(facebook, $gray-100, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }

                    &::after {
                        @include icon(twitter, $gray-100, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-youtube;
                    }

                    &::after {
                        @include icon(youtube, $gray-100, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-linkedin;
                    }

                    &::after {
                        @include icon(linkedin, $gray-100, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-instagram;
                    }

                    &::after {
                        @include icon(instagram, $gray-100, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-pinterest;
                    }

                    &::after {
                        @include icon(pinterest, $gray-100, 20px, 20px);
                    }
                }
            }
        }
    }

    &-social-share {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0 0 8px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $text-color;
                font-size: rem-convert(14px);
                line-height: 22px;
                padding: 5px 15px 5px 36px;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    content: "";
                }
            }

            &.facebook {
                a {
                    &::before {
                        @include icon(facebook, $text-color, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::before {
                        @include icon(twitter, $text-color, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::before {
                        @include icon(youtube, $text-color, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::before {
                        @include icon(linkedin, $text-color, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::before {
                        @include icon(instagram, $text-color, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::before {
                        @include icon(pinterest, $text-color, 20px, 20px);
                    }
                }
            }

            &.whatsapp {
                a {
                    &::before {
                        @include icon(whatsapp, $text-color, 20px, 20px);
                    }
                }
            }

            &.email {
                a {
                    &::before {
                        @include icon(email, $text-color, 20px, 20px);
                    }
                }
            }
        }
    }

    // @TODO
    &--downloads {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px;

        li {
            margin: 0 0 10px;

            a {
                display: block;
                text-decoration: none;

                img {
                    float: left;
                    margin: 0 20px 0 0;
                }

                .is-h3 {
                    margin: 0;
                }

                p {
                    margin: 0;
                }

                &:hover,
                &:focus {
                    color: $primary;
                    text-decoration: none;

                    .is-h3 {
                        color: $primary;
                    }
                }
            }
        }
    }

    // @TODO
    &--scores {
        display: flex;
        height: 20px;

        li {
            margin: 0 10px 0 0;

            .star {
                @include icon(star, $color-yellow, 20px, 20px);
                position: relative;
                display: block;
                width: 20px;
                height: 20px;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    content: "";
                }
            }

            &.active,
            &.active-half {
                ~ li {
                    .star {
                        &::after {
                            @include icon(star, $color-gray, 20px, 20px);
                        }
                    }
                }
            }

            &.active-half {
                .star {
                    @include icon(star, $color-gray, 20px, 20px);

                    &::after {
                        @include icon(star, $color-yellow, 20px, 20px);
                        width: 50%;
                        background-position: center left;
                    }
                }
            }
        }
    }
}
