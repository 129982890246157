h1,
.is-h1 {
    font-size: rem-convert(48px);
    line-height: rem-convert(58px);
    font-weight: $font-weight-bold;
    font-family: $heading-font;
}

h2,
.is-h2 {
    font-size: rem-convert(32px);
    line-height: rem-convert(48px);
    font-weight: $font-weight-bold;
    font-family: $heading-font;
}

h3,
.is-h3 {
    font-size: rem-convert(20px);
    line-height: rem-convert(28px);
    font-weight: $font-weight-bold;
    font-family: $heading-font;
}

h4,
.is-h4 {
    font-size: rem-convert(20px);
    line-height: rem-convert(28px);
    font-weight: $font-weight-bold;
    font-family: $heading-font;
}

h5,
.is-h5 {
    font-size: rem-convert(16px);
    line-height: rem-convert(24px);
    font-weight: $font-weight-bold;
    font-family: $heading-font;
}

h6,
.is-h6 {
    font-size: rem-convert(16px);
    line-height: rem-convert(24px);
    font-weight: $font-weight-bold;
    font-family: $heading-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    + .info {
        font-size: rem-convert(14px);
        margin-top: -$default-margin;
    }
}
