.header {
    position: sticky;
    top: -$header-height;
    height: $header-height;
    flex-shrink: 0;
    margin: 0 0 $default-margin;
    transition: all .3s ease-in-out;
    z-index: 999;
    background-color: var(--bs-body-bg);

    .search-container {
        position: relative;
        display: block;
        width: 100%;
        padding: 15px;
        margin: -77px 0 0;
        background-color: var(--bs-body-bg);
        transition: all .3s ease-in-out;
        z-index: 1;

        .form-search {
            margin: 0;
        }

        .search-suggestions {
            position: absolute;
            display: none;
            top: 100%;
            left: 0;
            right: 0;
            background-color: var(--bs-body-bg);
            opacity: .9;
            margin: 15px 0 0;

            &::after {
                position: absolute;
                left: 50%;
                top: 0;
                display: block;
                width: 16px;
                height: 16px;
                content: "";
                transform: rotate(45deg);
                border-top: 1px solid $color-gray;
                border-left: 1px solid $color-gray;
                background-color: $color-white;
                margin: -8px 0 0 -8px;
            }

            &.active {
                display: block;
            }

            li {
                display: block;
                border-bottom: 1px solid $color-gray;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    display: block;
                    padding: 7px 15px;
                    font-size: rem-convert(16px);
                    line-height: rem-convert(23px);
                }
            }
        }
    }

    &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px;
        position: relative;
        background-color: var(--bs-body-bg);
    }

    .logo {
        display: block;
        height: 56px;
        order: 1;
        margin: 0;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            height: 50px;
            margin: 0 auto;
        }

        img {
            width: auto;
            height: 100%;
            max-height: 100%;
        }
    }

    .navigation {
        order: 2;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            margin: 0;
        }
    }

    .btn {
        --bs-btn-bg: transparent;
        --bs-btn-hover-bg: transparent;
        --bs-btn-active-bg: transparent;
        --bs-btn-active-border-color: transparent;

        width: 50px;
        height: 50px;
        padding: 7px;
        margin: 3px 0 3px 10px;
        font-size: 0;
        transition: all .3s ease-in-out;
        z-index: 1;
        border: 0;

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 50%;
            left: 15px;
            display: block;
            margin: 0;
            transform: translateY(-50%);
        }

        &-nav {
            @include icon(menu, $primary, 25px, 25px);
            display: none;
            margin: 3px 10px 3px 0;
            order: 1;

            @include media-breakpoint-down(lg) {
                display: block;
                left: 15px;
                margin: 0;
            }

            &.active {
                @include icon(close, $primary, 20px, 20px);

                &:hover,
                &:focus {
                    @include icon(close, $color-black, 20px, 20px);
                }
            }
        }

        &-search {
            @include icon(search, $primary, 25px, 25px);
            order: 4;

            @include media-breakpoint-down(lg) {
                left: inherit;
                right: 0;
                margin: 0;
            }

            &:hover,
            &:focus {
                @include icon(search, $color-black, 25px, 25px);
            }

            &:focus-visible {
                outline: 1px solid $primary;
            }

            &.active {
                @include icon(close, $primary, 20px, 20px);

                &:hover,
                &:focus {
                    @include icon(close, $color-black, 20px, 20px);
                }

                &:focus-visible {
                    outline: 1px solid $primary;
                }
            }
        }

        &-language {
            @include icon(globe, $primary, 20px, 20px);
            order: 5;

            @include media-breakpoint-down(lg) {
                left: inherit;
                right: 50px;
            }

            &:hover,
            &:focus {
                @include icon(globe, $color-black, 20px, 20px);
            }

            &:focus-visible {
                outline: 1px solid $primary;
            }
        }
    }
}

.header-fixed {
    .header {
        box-shadow: 0 1px 3px rgba($color-black, .1);
    }
}

.scroll-up {
    .header {
        top: 0;
    }
}

.primary-search-active:not(.scroll-down) {
    .header {
        .search-container {
            margin: 0;
        }
    }
}
